import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import App from "./App";
import FooterDrop from "./js/footerDrop";
import DaySattaResult from "./js/daySattaResult";
import DetailAcord from "./js/detailAccord";
import Disclaimer from "./js/disclaimer";

const RootComponent = () => {
  const location = useLocation();
  const isDisclaimerPage = location.pathname === "/disclaimer";

  return (
    <div className="bg-dark">
      {/* <div className=''> */}
      {!isDisclaimerPage && (
        <div className="container-fluid">
          <App />

          {/* <DaySattaResult /> */}
          <DetailAcord />
        </div>
      )}
      <Routes>
        <Route path="/disclaimer" element={<Disclaimer />} />
      </Routes>
    </div>
  );
};

ReactDOM.render(
  <Router>
    <RootComponent />
  </Router>,
  document.getElementById("root")
);
