// import logo from './logo.svg';
// import './App.css';
import moment from 'moment';
const momenttz = require('moment-timezone')
// import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';


function Disclaimer() {

    return (
        <div className='col-12 pt-3 text-center disclaimer container-fluid'>
            <div className='header'>
                <h5>*WWW.SATTASPORT.COM*</h5>
                <h6>!!! SATTA KING !!!</h6>
            </div>
            <marquee className='text-warning mt-2'>satta king, satta, sattaking, satta result, satta king up,satta bazar, satta king 2017, gali satta, matka, black satta, satta king 2018, upgameking, delhi satta, desawar, satta king result, satta king online, black satta king, delhi satta king,
            </marquee>
            <div className='header_2'>
                <h6>SATTAKING, SATTA KING, SATTA RESULT
                </h6>
            </div>
            <div className='col-12 section'>
                <div className='row main-row'>
                    <div className='col-6 col-md-6 text-center text-danger fw-b link border'><a href='/'>HOME</a></div>
                    <div className='col-6 col-md-6 text-center text-danger fw-b link border'><a href='/'>MORE</a></div>
                </div>
            </div>

            <div className='disContent pt-3'>
                <span className='fw-bold mb-2'>   DISCLAIMER</span>
                <p>At Satta King, accessible from https://sattasport.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Satta King and how we use it.

                </p>
            </div>


            <div className='log pt-3'>
                <span className='fw-bold mb-2'>  LOG FILES</span>
                <p>Satta King follows a regular procedure of exploitation log files. These files log guests once they visit websites. All hosting firms do that and a locality of hosting services' analytics. the knowledge collected by log files embrace web protocol (IP) addresses, browser kind, web Service supplier (ISP), date and time stamp, referring/exit pages, and probably the amount of clicks. These don't seem to be coupled to any info that's in person identifiable . the aim of the knowledge is for analyzing trends, administering the location, following users' movement on the web site, and gathering demographic info.

                </p>
            </div>
            <div className='coockie pt-3'>
                <span className='fw-bold mb-2'>COOKIES AND WEB</span>
                <p>
                    Like any different web site, Satta King uses 'cookies'. These cookies ar accustomed store data together with visitors' preferences, and therefore the pages on the web site that the visitant accessed or visited.  is employed to optimize the users' expertise by customizing our website content supported visitors' browser kind and/or different information.

                </p>
            </div>
            <div className='google pt-3'>
                <span className='fw-bold mb-2'>GOOGLE DART COOKIE</span>
                <p>
                    Google is one in every of a third-party trafficker on our web site. It conjointly uses cookies, called DART cookies, to serve ads to our web site guests based mostly upon their visit to computer website.com and alternative sites on the web. However, guests could like better to decline the utilization of DART cookies by visiting the Google ad and content network Privacy Policy at the subsequent uniform resource locator URL – https://policies.google.com/technologies/ads

                </p>
            </div>


            <div className='advertisePart pt-3'>
                <span className='fw-bold mb-2'>OUR ADVERTISING PARTNERS</span>
                <p>
                    Some of advertisers on our computing device would possibly use cookies and internet beacons. Our advertising partners area unit listed below. each of our advertising partners has their own Privacy Policy for his or her policies on user data. For easier access, we tend to tend to hyperlinked to their Privacy Policies below.
                </p>
            </div>

            <div className='privacy pt-3'>
                <span className='fw-bold mb-2'> PRIVACY POLICIES</span>
                <p>
                    You may consult this list to seek out the Privacy Policy for every of the advertising partners of Satta King. Our Privacy Policy was created with the assistance of the GDPR Privacy Policy Generator and the Privacy Policy Generator from TermsFeed plus the Terms and Conditions Template.
                </p>
                <p>
                Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or netBeacons that ar employed in their various advertisements and links that seem on Satta King, that ar sent on to users' browser. They mechanically receive your information processing address once this happens. These technologies live wont to measure the effectiveness of their advertising campaigns and/or to modify the advertising content that you simply see on websites that you simply visit.
                </p>
                <p>
                    Note that Satta King has no access to or management over these cookies that aremployed by third-party advertisers.
                </p>
            </div>

            <div className='thirdParty pt-3'>
                <span className='fw-bold mb-2'>THIRD PARY POLICIES</span>
                <p>
                    Satta King's Privacy Policy doesn't apply to different advertisers or websites. Thus, we have a tendency to ar advising you to consult the individual Privacy Policies of thosethird-party ad servers for a lot of elaborate info. it should embrace their practices and directions regarding the way to opt-out of sure choices. you will notice a whole list of those Privacy Policies and their links here: Privacy Policy Links.
                </p>
                <p>
                    You can value more highly to disable cookies through your individual browser choices. to grasp a lot of elaborate info regarding cookie management with specific internetbrowsers, it are often found at the browsers' individual websites. What ar Cookies?
                </p>
            </div>

            <div className='children pt-3'>
                <span className='fw-bold mb-2'>CHILDREN'S INFORMATION

                </span>
                <p>
                    Another a part of our priority is adding protection for youngsters whereas victimisationthe web. we tend to encourage oldsters and guardians to watch, participate in, and/or monitor and guide their on-line activity.
                </p>
                <p>
                    Satta King doesn't wittingly collect any Personal diagnosable data from kids underneaththe age of thirteen. If you're thinking that that your kid provided this type of data on our web site, we tend to powerfully encourage you to contact U.S. now and that we can do our greatest efforts to promptly take away such data from our records.
                </p>
            </div>

            <div className='online pt-3'>
                <span className='fw-bold mb-2'>ONLINE POLICY</span>
                <p>
                    This Privacy Policy applies solely to our on-line activities and is valid for guests to our web site with regards to the knowledge that they shared and/or collect in State King.
                </p>
                <p>
                    This policy is not applicable to any knowledge collected offline or via channels with the exception of this internet site.
                </p>
            </div>

            <div className='about pt-3 pb-2'>
                <span className='fw-bold mb-2'>ABOUT

                </span>
                <p>
                    WWW.sattasport.COM is a non commerical website.Viewing This WebSite Is Your Own Risk, All The Information Shown On Website Is Sponsored And We Warn You That Matka Gambling/Satta May Be Banned Or banned In Your Country..., we have a tendency to don't seem to be chargeable for Any problems Or Scam..., we have a tendency to Respect All Country Rules/Laws... If You Not trust Our web site Disclaimer... Please Quit Our Site Right Now.Thanks
                </p>
            </div>

        </div>
    );
}

export default Disclaimer;
