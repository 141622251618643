import moment from "moment";
import { Col, Table } from "antd";
import React, { useState, useEffect } from "react";

{
  /* <Table dataSource={dataSource} columns={columns} />; */
}
function MonthlySatta({ gamedata, dropValue }) {
  const [data, setData] = useState([]);
  const [gameResult, setGameResult] = useState([]);
  const [monthSndYear, setMonth] = useState([]);
  let [columns, setcolumn] = useState([]);
  var date = new Date();

  // console.log('dropValue', dropValue.selectedOption)
  var currentMonthDays = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();
  const currentMonth = moment().tz("Asia/Kolkata").month();
  const currentYear = moment().tz("Asia/Kolkata").year();
  // console.log('currentMonthDays', currentMonthDays)
  // console.log('getCurrentYear', getCurrentYear)
  // console.log('currentMonth', currentMonth)
  useEffect(() => {
    if (gamedata) {
      setData(gamedata);
      // console.log('data', data)
    }
  });
  var selectMonthDrop;
  useEffect(() => {
    if (dropValue) {
      setGameResult(dropValue);
      // console.log('gameResult', gameResult)
      if (gameResult == "") {
        selectMonthDrop = moment().tz("Asia/Kolkata").format("MMMM");
        // console.log('if======>', selectMonthDrop)
      } else {
        selectMonthDrop = moment(
          `${dropValue?.year}-${dropValue?.month}-01`
        ).format("MMMM");
        // console.log('else======>', selectMonthDrop)
      }
      // console.log(gameResult);
      // console.log()
    } else {
    }
  });
  // useEffect(() => {
  //     if (dropValue) {
  //         setGameResult(dropValue)
  //     }
  // })
  useEffect(() => {
    if (data.length > 0) {
      let array = Object.keys(data[0]);
      for (let i = 0; i < array.length; i++) {
        array[i] = {
          title: array[i] == "day" ? selectMonthDrop : array[i],
          dataIndex: array[i],
          key: array[i],
        };
      }
      setcolumn(array);
    }
  }, [data]);
  useEffect(() => {
    // monthYear = new Date()
    // monthYear = "" + moment(monthYear).format('MMM-yyyy')
    // setMonth(monthYear)
    fetch("https://api.sattakingvip.co.in/getmonthdata", {
      method: "POST", // or 'PUT' depending on your requirements
      headers: {
        "Content-Type": "application/json", // specify the content type
      },
      body: JSON.stringify({
        month: currentMonth + 1,
        year: currentYear,
        gameName: "",
        result: "",
        days: currentMonthDays,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setData(json);
        // console.log(json)
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="pt-3 monthYrTbl" id="monthTable">
      {!(dropValue == null) && (
        <h5
          className="text-center bg-dark text-white p-2"
          style={{ display: "block" }}
        >
          {/* {dropValue?.selectedOption?.value} MONTHLY RECORD CHART {dropValue?.selecYear?.value} */}
          {dropValue?.gameName} MONTHLY RECORD CHART {selectMonthDrop} -{" "}
          {dropValue?.year || currentYear}
        </h5>
      )}
      <div className="table-responsive" id="scrollbar1">
        <Table dataSource={data} columns={columns} pagination={false} />;
      </div>
    </div>
  );
}

export default MonthlySatta;
